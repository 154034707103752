<template>
  <div id="app">
    <div v-if="logged">
      <SignalrUser />
      <NewHeader backgroundColor="transparent">
        <NewIconMolded :icons="icons" />
        <NewAccount :items="items" :exited="confirmLogout" />
      </NewHeader>
      <NewMenu :urlPhoto="urlBaseImage + (userLogged.user.photo ? userLogged.user.photo : 'noImageEdit.png')" />
      <!-- <Header backgroundColor="#577696">
        <IconMolded :icons="icons" />
        <Account :items="items" :exited="confirmLogout" />
      </Header>
      <Menu baseColor="#1B3446" urlImage="https://espaco.blob.core.windows.net/nixloc-assets/logo_icon_nixloc.png"
        :urlPhoto="urlBaseImage + (userLogged.user.photo ? userLogged.user.photo : 'noImageEdit.png')" />-->
      <Modal title="Tem certeza que deseja sair?" :width="450" :height="750" v-if="showModal('confirmLogout')">
        <div class="text-center">
          <br />
          <Button _key="btnConfirmLogout" type="edit" title="Cancelar" classIcon="fas fa-create" size="small"
            :clicked="logout" />
          <Button _key="btnConfirmLogout" type="danger" title="Sim, tenho" classIcon="fas fa-create" size="medium"
            :clicked="logout" />
        </div>
      </Modal>
      <Modal title="Mensageria" :width="1100" :height="750" v-if="showModal('notifications')">
        <Messaging v-if="showModal('notifications')" />
      </Modal>
      <Modal title="Perfil" :width="600" :height="750" v-if="showModal('profile')">
        <Profile v-if="showModal('profile')" />
      </Modal>
      <Modal title="Consulta de Disponibilidade" :width="1100" :height="750" v-if="showModal('availability')">
        <AvailabilityProduct />
      </Modal>
    </div>
    <div @mouseover="handleMouseOver()">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>

import SignalrUser from './components/modules/adm/login/SignalrUser.vue'
import IconMolded from "@nixweb/nixloc-ui/src/component/layout/IconMolded.vue";
import NewIconMolded from "@nixweb/nixloc-ui/src/component/layout/NewIconMolded.vue";
import Account from "@nixweb/nixloc-ui/src/component/layout/Account.vue";
import NewAccount from "@nixweb/nixloc-ui/src/component/layout/NewAccount.vue";
import Header from "@nixweb/nixloc-ui/src/component/layout/Header.vue";
import NewHeader from "@nixweb/nixloc-ui/src/component/layout/NewHeader.vue";
import Menu from "@nixweb/nixloc-ui/src/component/layout/Menu.vue";
import NewMenu from "@nixweb/nixloc-ui/src/component/layout/NewMenu.vue";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Messaging from './components/modules/notifications/Messaging.vue'
import Comunication from './components/modules/notifications/Comunication.vue'
import Profile from './components/modules/adm/user/Profile.vue'
import AvailabilityProduct from './components/modules/stock/availability-product/AvailabilityProduct.vue'

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
  name: "App",
  components: { Menu, NewMenu, NewHeader, Header, NewIconMolded, IconMolded, Account, NewAccount, Modal, Button, SignalrUser, Messaging, Comunication, Profile, AvailabilityProduct },
  data() {
    return {
      environment: "",
      urlBaseImage: "https://espaco.blob.core.windows.net/nixloc-photo-user/",
      items: [],
      icons: [
        {
          tooltip: "Dashboard",
          eventName: "navegateToDashboard",
          icon: "fa-duotone fa-solid fa-chart-column",
          module: "Dashboard",
        },
        {
          tooltip: "Calendário",
          eventName: "navegateToCalendar",
          icon: "fa-duotone fa-solid fa-calendar-days",
          module: "Calendar",
        },
        {
          tooltip: "Tarefa",
          eventName: "navegateToJob",
          icon: "fa-duotone fa-solid fa-check-double",
          module: "Job",
        },
        {
          tooltip: "Consulta de Disponibilidade",
          eventName: "openAvailability",
          icon: "fa-sharp-duotone fa-solid fa-radar",
          module: "Notification",
        },
        {
          tooltip: "Mensageria",
          eventName: "openNotificarions",
          icon: "fa-duotone fa-solid fa-messages",
          module: "Notification",
        },
        {
          tooltip: "Treinamento",
          eventName: "navegateToCourse",
          icon: "fa-sharp-duotone fa-solid fa-graduation-cap",
          module: "Notification",
        },

      ],
      theme: localStorage.getItem('theme'),
    };
  },
  mounted() {
    this.loadTheme();
  },
  created() {
    this.environment = process.env.VUE_APP_API_URL == "https://backend-cliente-prod.azurewebsites.net/" ? "prod" : "dev";
  },
  beforeUpdate() {
    let documentId = this.$route.params.documentId;
    let nameFileParameter = this.$route.params.nameFileParameter;
    let code = this.$route.params.code;

    let userName = "";
    if (this.userLogged.user) {
      userName = this.userLogged.user.userName;
    }

    const shouldRedirectToLogin = !this.logged &&
      this.$route.path !== "/register" &&
      !nameFileParameter && !documentId &&
      userName !== "UserTemp" && !code;

    if (shouldRedirectToLogin)
      this.$router.replace({ name: "login" });

  },
  methods: {
    ...mapMutations("user", ["addUserLogged"]),
    ...mapMutations("generic", ["openModal", "addEvent"]),
    handleMouseOver() {
      this.addEvent({ name: "handleMouseOver" });
    },
    confirmLogout() {
      this.openModal("confirmLogout");
    },
    logout() {
      this.addUserLogged({});
      localStorage.setItem("codeBrowser", "");
      window.location.replace("/login");
    },
    loadTheme() {
      var themeStorage = localStorage.getItem('theme');
      this.theme = themeStorage == "" ? "light" : themeStorage;

      const body = document.body;
      if (this.theme == 'dark') {
        body.classList.add('dark-theme');
      } else {
        body.classList.remove('dark-theme');
      }
    },
    closeMenu() {
      // this.addEvent({ name: "closeMenu" });
      // a função acima está dando conflito quando clica no link da locação no operacional
    }
  },
  computed: {
    ...mapState("user", ["userLogged"]),
    ...mapGetters("generic", ["event", "showModal"]),
    logged() {
      if (this.userLogged.token) return true;
      return false;
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "navegateToCalendar")
          this.$router.push({
            name: "calendar",
          });

        if (event.name == "navegateToCourse")
          this.$router.push({
            name: "course",
          });

        if (event.name == "navegateToDashboard")
          this.$router.push({
            name: "dashboard",
          });

        if (event.name == "navegateToJob")
          this.$router.push({
            name: "jobList",
          });

        if (event.name == "changeTheme") {
          this.theme = event.data ? "dark" : "light";
          localStorage.setItem('theme', this.theme);
          this.loadTheme();
        }

        if (event.name == "openNotificarions")
          this.openModal("notifications");

        if (event.name == "openAvailability")
          this.openModal("availability");

        if (event.name == "clickedProfile")
          this.openModal("profile");

        if (event.name == "isSidebarCollapsed") {
          this.isSidebarCollapsed = event.data;
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
/* não remover essa classe, pois não funciona no dark-theme.css*/
.dark-theme .select {
  border: 1px solid #444C56 !important;
  background-color: #22272E !important;
}

.dark-theme .classCss {
  border: 0px solid #444C56 !important;
  background-color: transparent !important;
}

.title-sub {
  color: black;
}
</style>